import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BCardText,
    BInputGroupAppend,
    BFormGroup, BFormSelect,
    BDropdownItem,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BCardBody, VBPopover,
    BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import gql from "graphql-tag";
import { avatarText } from "@core/utils/filter";

import moment from 'moment'
import 'moment-timezone';
import 'moment/locale/pt-br';
import { Form } from 'vform';

import {  Chrome } from 'vue-color'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from './pt.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const ADD = gql`
mutation addCategory($description: String!, $color: String!) {
    insert_categories(objects: {description: $description, color: $color}) {
      affected_rows
    }
  }
  `;

const UPDATE = gql`
mutation updateCategory($uuid_category : uuid!, $description: String!, $color: String!) {
    update_categories(_set: {description: $description, color: $color}, where: {uuid_category: {_eq: $uuid_category}}) {
      affected_rows
    }
  }
  `;

const DELETE = gql`
mutation deleteCategory($uuid_category : uuid!) {
    delete_categories(where: {uuid_category: {_eq: $uuid_category}}) {
      affected_rows
    }
  }
  `;

const GET = gql`
query getCategories {
	categories {
		uuid_category
    description
    color
	}
}
  `;

export default {
    components: {
        'chrome-picker': Chrome,
        ToastificationContent,
        quillEditor,
        flatPickr,
        BCardText,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BCardBody,
        BCardCode,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup, BFormSelect,
        BLink,
        BInputGroupAppend,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        BInputGroupPrepend,

        vSelect,
    },
    directives: {
        'b-popover': VBPopover,
    },
    data() {
        return {
            titleForm: 'Adicionar categoria',
            snowOption: null,
            color :  { hex : '#000' },
            editMode: false,
            optionsDate:
            {
                locale: Portuguese,
                enableTime: false,
                dateFormat: 'm-d-Y',
                time_24hr: false,
                minDate: 'today'
            }
            ,
            expires_at: null,
            form: new Form({
                uuid_category: null,
                description: null,

            }),
            options: {
                date: {
                    date: true,
                    delimiter: '/',
                    datePattern: ['d', 'm', 'Y'],
                },
            },
            perPage: 5,
            totalRows: 1,
            currentPage: 1,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [
                { key: 'description', label: 'Nome', sortable: true },
                { key: 'color', label: 'Cor', sortable: true },
                { key: 'actions', label: 'Ações', sortable: false },
            ],
            items: [],
        }
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({ text: f.label, value: f.key }))
        },
    },
    created() {
        moment.locale('pt-br')

        this.getRecords();

    },
    methods: {
        newCities() {
            this.titleForm = 'Adicionar Categoria',
                this.editMode = false;
            this.form.reset();
            this.$refs['categoriesModal'].show()

        },
        newCategory() {
            let {
                description,
            } = this.$data.form;
            let color = this.color.hex

            this.$apollo.mutate({
                mutation: ADD,
                variables: {
                    description,
                    color,
                }
            }).then(() => {
                this.getRecords();
                this.$refs['categoriesModal'].hide()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sucesso',
                        icon: 'CheckIcon',
                        text: 'Categoria adicionada!',
                        variant: 'success',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Erro',
                        icon: 'XIcon',
                        text: 'Categoria não adicionada! ' + error,
                        variant: 'danger',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            });
        },
        editCategory(c) {
            this.titleForm = 'Editando Categoria ';
            this.editMode = true;
            this.$data.form.fill(c);
            this.color = { hex : c.color}
            this.$refs['categoriesModal'].show()

        },
        updateCategory() {
            let {
                uuid_category,
                description,
            } = this.$data.form;
            let color = this.color.hex

            this.$apollo.mutate({
                mutation: UPDATE,
                variables: {
                    description,
                    color,
                    uuid_category: uuid_category,
                }
            }).then(() => {
                this.getRecords();
                this.$refs['categoriesModal'].hide()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sucesso',
                        icon: 'CheckIcon',
                        text: 'Categoria atualizada!',
                        variant: 'success',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Erro',
                        icon: 'XIcon',
                        text: 'Erro ao atualizar Categoria! ' + error,
                        variant: 'danger',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            });
        },

        deleteCategory(uuid) {

            this.$bvModal
                .msgBoxConfirm('Deseja realmente excluir esta Categoria?.', {
                    title: 'ATENÇÃO!',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value == true) {
                        this.$apollo.mutate({
                            mutation: DELETE,
                            variables: {
                                uuid_category: uuid
                            }
                        }).then(() => {
                            this.getRecords();
                            this.$refs['categoriesModal'].hide()
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Sucesso',
                                    icon: 'CheckIcon',
                                    text: 'Categoria deletada!',
                                    variant: 'success',
                                },
                            },
                                {
                                    position: 'bottom-right',
                                })
                        }).catch((error) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Erro',
                                    icon: 'XIcon',
                                    text: 'Erro ao excluir a Categoria! ' + error,
                                    variant: 'danger',
                                },
                            },
                                {
                                    position: 'bottom-right',
                                })
                        });
                    }
                })


        },
        formatFullDate(data) {
            return moment(data).format("DD/MM/YY [às] HH:mm:ss")
        },
        formatDatetoUpdate(data) {
            return moment(data).format("MM-DD/YY")
        },
        getRecords() {
            this.$apollo.query({
                query: GET,
                fetchPolicy: 'no-cache',
            }).then((data) => {
                this.items = data['data']['categories'];
                this.totalRows = this.items.length
            });
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },
    setup() {
        return {
            avatarText,
        }
    }


}